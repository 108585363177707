// temp_33950_file
import { useEffect, useRef } from 'react';

import { Card, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import styles from './banner.less';

const TrlKeys = {
    titleOne: 'investDay.banner.title.one',
    titleTwo: 'investDay.banner.title.two',
};

const BIG_PROMO_ANIMATION_DELAY = 5500;

const InvestDayBanner: TranslatedComponent = ({ trls }) => {
    const { isGtM } = useBreakpoint();
    const userType = useSelector((state) => state.userType);
    const { bigPromoVisible, link } = useSelector((state) => state.investDay);
    const timer = useRef<ReturnType<typeof setTimeout>>();

    const handleAnimationEnd = () => {
        document.body.classList.remove(styles.bannerAnimation);
    };

    useEffect(() => {
        const isPrefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

        if (isPrefersReducedMotion && isGtM && bigPromoVisible) {
            window.dispatchEvent(new CustomEvent('invest-day-request-show-big-promo'));
            return;
        }

        if (isGtM && bigPromoVisible) {
            document.body.classList.add(styles.bannerAnimation);

            timer.current = setTimeout(() => {
                if (isGtM && bigPromoVisible) {
                    window.dispatchEvent(new CustomEvent('invest-day-request-show-big-promo'));
                }
            }, BIG_PROMO_ANIMATION_DELAY);
        }
    }, [isGtM, bigPromoVisible]);

    useEffect(() => {
        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    }, []);

    return (
        <>
            {userType === UserType.Employer && <VSpacing default={0} xs={24} s={24} m={24} />}

            <Card showBorder borderRadius={24} padding={24} stretched Element="a" href={link} target="_blank">
                <div className={styles.banner} onAnimationEnd={handleAnimationEnd}>
                    <div className={styles.bannerRow1}>{trls[TrlKeys.titleOne]}</div>

                    <div className={styles.bannerRow2}>{trls[TrlKeys.titleTwo]}</div>

                    <div className={styles.bannerRow3}>2024</div>

                    <div className={styles.bannerRow4}>
                        <div>03</div>
                        <div>.10</div>
                    </div>
                </div>
            </Card>

            {userType === UserType.Employer ? <VSpacing default={24} xs={0} s={0} m={0} /> : <VSpacing default={24} />}
        </>
    );
};

export default translation(InvestDayBanner);
