import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { GridColumn, GridRow, VSpacing } from '@hh.ru/magritte-ui';

import EmployerEventList from 'src/components/IndexPageEmployer/components/EmployerEventList';
import LastSearches from 'src/components/IndexPageEmployer/components/LastSearches';
import Updates from 'src/components/IndexPageEmployer/components/Updates';
import NewsBox from 'src/components/NewsBox';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    articlesTitle: 'index.Articles',
    newsTitle: 'index.newsTitle',
};

const DEFAULT_WIDGET_HEIGHT = 360;

const Dashboard = ({ trls }) => {
    const isZp = useIsZarplataPlatform();
    const employerArticles = useSelector((state) => state.employerArticles);
    const employerNews = useSelector((state) => state.employerNews);
    const ref = useRef(null);
    const [widgetHeight, setWidgetHeight] = useState(DEFAULT_WIDGET_HEIGHT);

    useEffect(() => {
        const { height } = ref.current?.getBoundingClientRect();

        if (height) {
            setWidgetHeight(height);
        }
    }, []);

    return (
        <GridRow>
            <GridColumn xs={4} s={8} m={12} l={9}>
                <VSpacing default={40} />

                <GridRow>
                    <GridColumn xs={4} s={8} m={12} l={6} flexibleContent>
                        <GridRow>
                            <GridColumn xs={4} s={8} m={6} l={3} flexibleContent>
                                <EmployerEventList ref={ref} />
                                <VSpacing default={24} gteM={0} />
                            </GridColumn>

                            <GridColumn xs={4} s={8} m={6} l={3} flexibleContent>
                                <LastSearches maxHeight={widgetHeight} />
                            </GridColumn>
                        </GridRow>

                        <VSpacing default={24} gteL={0} />
                    </GridColumn>

                    <GridColumn xs={4} s={8} m={12} l={3} flexibleContent>
                        {isZp ? (
                            <NewsBox
                                items={employerArticles}
                                title={trls[TrlKeys.articlesTitle]}
                                href="/articles/employers"
                                isArticle
                                view="cell"
                            />
                        ) : (
                            <Updates maxHeight={widgetHeight} />
                        )}
                    </GridColumn>
                </GridRow>

                <VSpacing default={40} />
            </GridColumn>

            {isZp && (
                <GridColumn xs={4} s={8} m={12} l={3}>
                    <VSpacing xs={0} s={0} default={40} />

                    <NewsBox
                        items={employerNews}
                        title={trls[TrlKeys.newsTitle]}
                        href="/articles/site-news"
                        view="cell"
                    />
                </GridColumn>
            )}
        </GridRow>
    );
};

Dashboard.propTypes = {
    trls: PropTypes.object,
};

export default translation(Dashboard);
