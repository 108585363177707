import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';
import { push } from 'connected-react-router';

import updateResumeCardShown from '@hh.ru/analytics-js-events/build/xhh/applicant/experiments/PORTFOLIO-26342/update_resume_card_shown';
import buttonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/main/button_click';
import { HhcaptchaType, RecaptchaType } from '@hh.ru/hhcaptcha';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import Debug from 'HHC/Debug';
import { useNotification } from 'src/components/Notifications/Provider';
import {
    resumeBatchUpdateSuccessNotification,
    resumeBatchUpdateErrorNotification,
} from 'src/components/Notifications/ResumeBatchUpdate';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

import Card, { CardType } from 'src/components/IndexPageApplicant/Actions/Card';
import { useCanUpdateResumes } from 'src/components/IndexPageApplicant/Actions/useCanUpdateResumes';

interface BatchUpdateResponseData {
    hhcaptcha?: HhcaptchaType;
    recaptcha?: RecaptchaType;
    updateError?: string;
}

declare global {
    interface FetcherPostApi {
        '/shards/resume/batch_update': {
            queryParams: void;
            body: null;
            response: {
                data?: BatchUpdateResponseData;
            };
        };
    }
}

const TrlKeys = {
    title: 'index.applicant.actions.updateResumes.title.redesign',
    update: 'index.applicant.actions.updateResumes.update',
};

const setResumesForUpdateAction = makeSetStoreField('resumesForUpdate');

const createCaptchaUrl = (captchaState: string | null | undefined) => {
    const url = urlParser('/account/captcha');

    url.params = {
        state: captchaState,
        backurl: document.location.toString(),
    };

    return url.href;
};

const UpdateResumes: TranslatedComponent = ({ trls }) => {
    const [isLoading, setIsLoading] = useState(false);
    const resumesForUpdate = useSelector((state) => state.resumesForUpdate);
    const canUpdateResumes = useCanUpdateResumes();
    const dispatch = useDispatch();
    const updateResumesCardRef = useRef(null);
    const { addNotification } = useNotification();

    useEffect(() => {
        if (updateResumesCardRef.current) {
            updateResumeCardShown(updateResumesCardRef.current);
        }
    }, []);

    if (!canUpdateResumes) {
        return null;
    }

    const handleClick = () => {
        buttonClick({ buttonName: 'update_resume_card' });
        setIsLoading(true);
        fetcher
            .post('/shards/resume/batch_update')
            .then(() => {
                addNotification(resumeBatchUpdateSuccessNotification);
                dispatch(setResumesForUpdateAction([]));
            })
            .catch((error: AxiosError<BatchUpdateResponseData>) => {
                if (error.response?.data?.hhcaptcha?.isBot || error.response?.data?.recaptcha?.isBot) {
                    const captchaState =
                        error.response?.data?.hhcaptcha?.captchaState || error.response?.data?.recaptcha?.captchaState;
                    dispatch(push(createCaptchaUrl(captchaState)));
                    return;
                }

                Debug.log('out error', new Error('Error batch updating resumes'), {
                    reason: error.response?.data?.updateError,
                    length: resumesForUpdate?.length,
                    resumes: resumesForUpdate?.join(','),
                });
                console.error(error);
                addNotification(resumeBatchUpdateErrorNotification);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Card
            dataQa="applicant-index-nba-action_update-resumes"
            caption={trls[TrlKeys.title]}
            linkText={trls[TrlKeys.update]}
            type={CardType.Up}
            isDisabled={isLoading}
            onClick={handleClick}
            cardRef={updateResumesCardRef}
        />
    );
};

export default translation(UpdateResumes);
