import classnames from 'classnames';

import { Action, Card, Title, useBreakpoint, VSpacing as MagritteVSpacing } from '@hh.ru/magritte-ui';
import { VkSolidSize24, TelegramSolidSize24, ViberSolidSize24 } from '@hh.ru/magritte-ui/service';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance } from 'bloko/blocks/button';
import Gap from 'bloko/blocks/gap';
import { H2 } from 'bloko/blocks/header';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import StaticImg from 'src/components/StaticImg';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import { ChatBotMessengerTypes } from 'src/models/chatBot';

import styles from './messengers-bots.less';

const TrlKeys = {
    header: 'index.messengers.bots.header',
    headerRedesign: 'index.messengers.bots.header.redesign',
    subheader: {
        mobile: 'index.messengers.bots.subheader.mobile',
        desktop: 'index.messengers.bots.subheader.desktop',
    },
    texts: {
        [ChatBotMessengerTypes.Viber]: 'messengers.bots.button.viber',
        [ChatBotMessengerTypes.Telegram]: 'messengers.bots.button.telegram',
        [ChatBotMessengerTypes.Vk]: 'messengers.bots.button.vk',
    },
    qrDescription: 'messengers.bots.qrCode.description',
};

interface MessengersBotsProps {
    isInline?: boolean;
}

const typeToIconMap = {
    [ChatBotMessengerTypes.Telegram]: TelegramSolidSize24,
    [ChatBotMessengerTypes.Vk]: VkSolidSize24,
    [ChatBotMessengerTypes.Viber]: ViberSolidSize24,
};

const MessengersBots: TranslatedComponent<MessengersBotsProps> = ({ trls, isInline }) => {
    const { messengers, genericUserType, source, page } = useSelectorNonNullable((state) => state.chatBot);
    const isMagritte = useMagritte();
    const { isMobile } = useBreakpoint();

    if (isMagritte) {
        const renderSocial = () => {
            return (
                <div className={styles.socialWrapper}>
                    {messengers.map(({ type, href }) => {
                        return (
                            <Link to={href} key={type} target="_blank">
                                <Action icon={typeToIconMap[type]} onClick={() => undefined} />
                            </Link>
                        );
                    })}
                </div>
            );
        };
        return (
            <Card padding={24} borderRadius={24} stretched verticalStretched style="extra-5">
                <div className={styles.wrapper}>
                    <MagritteVSpacing default={8} gteL={0} />
                    <div className={styles.topSection}>
                        <div>
                            <Card padding={12} showShadow borderRadius={12}>
                                <StaticImg
                                    className={styles.qrImage}
                                    path={`/images/hh/chatbot/${page}/qr-telegram-${genericUserType}-${source}.png`}
                                    alt={'qr-code-telegram'}
                                />
                            </Card>
                        </div>
                        <div>{renderSocial()}</div>
                    </div>
                    <MagritteVSpacing default={0} gteM={32} />
                    <Title
                        Element="h3"
                        description={trls[isMobile ? TrlKeys.subheader.mobile : TrlKeys.subheader.desktop]}
                        size="medium"
                        descriptionStyle="primary"
                        alignment={isMobile ? 'center' : 'left'}
                    >
                        {trls[TrlKeys.header]}
                    </Title>
                    <div className={styles.linksBottom}>
                        <MagritteVSpacing default={16} />
                        {renderSocial()}
                    </div>
                    <MagritteVSpacing default={4} gteL={0} />
                </div>
            </Card>
        );
    }

    return (
        <>
            <H2 Element="h3">{trls[TrlKeys.header]}</H2>
            <VSpacing base={4} />
            <div
                className={classnames('messengers-bots-content', {
                    'messengers-bots-content_inline': isInline,
                })}
            >
                <div className="messengers-bots-buttons">
                    <div className="messengers-bots-buttons__wrapper">
                        {messengers.map(({ type, href }) => {
                            return (
                                <div className="messengers-bots-button" key={type}>
                                    <Button
                                        Element={Link}
                                        to={href}
                                        appearance={ButtonAppearance.Outlined}
                                        stretched
                                        target="_blank"
                                    >
                                        <span className="messengers-bots-button__content">
                                            <span
                                                className={classnames('messengers-bots-button__logo', {
                                                    [`messengers-bots-button__logo_${type}`]: type,
                                                })}
                                            />
                                            <span className="messengers-bots-button__text">
                                                {trls[TrlKeys.texts[type]]}
                                            </span>
                                        </span>
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <Gap top={!isInline} />
                <div className="messengers-bots-qr-code-wrapper">
                    <StaticImg
                        className="messengers-bots-qr-code"
                        path={`/images/hh/chatbot/${page}/qr-telegram-${genericUserType}-${source}.png`}
                        alt={'qr-code-telegram'}
                    />
                    <div className="messengers-bots-qr-code-description">
                        <Text size={TextSize.Small} importance={TextImportance.Secondary}>
                            {trls[TrlKeys.qrDescription]}
                        </Text>
                    </div>
                </div>
            </div>
        </>
    );
};

export default translation(MessengersBots);
