// temp_33950_file
import { useEffect, useCallback, useRef } from 'react';

import { Button, GridLayout, GridRow } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Notices from 'Modules/Notices';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';

import styles from './styles.less';

const SCROLL_OFFSET = 80;

const TrlKeys = {
    title: 'investDay.title',
    description: 'investDay.description',
    button: 'investDay.button',
};

const InvestDay: TranslatedComponent = ({ trls }) => {
    const [isVisible, show, hide] = useOnOffState(false);
    const { link, bigPromoViewedEvent } = useSelector((state) => state.investDay);
    const imageRef = useRef<HTMLDivElement | null>(null);

    const handleClose = useCallback(() => {
        hide();

        document.body.classList.remove(styles.bigPromoAnimated);

        const isPrefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

        if (isPrefersReducedMotion && imageRef.current) {
            imageRef.current.style.display = 'none';
        }
    }, [hide]);

    const handleRequestShow = useCallback(() => {
        window.scrollTo({ top: 0 });

        show();

        if (imageRef.current) {
            imageRef.current.style.display = 'flex';
            // eslint-disable-next-line babel/no-unused-expressions
            imageRef.current.offsetHeight; // reflow
        }

        document.body.classList.add(styles.bigPromoAnimated);

        const isPrefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

        if (isPrefersReducedMotion) {
            Notices.markAsViewed(bigPromoViewedEvent);
        }
    }, [bigPromoViewedEvent, show]);

    const handleScroll = useCallback(() => {
        if (isVisible && window.scrollY >= SCROLL_OFFSET) {
            handleClose();
        }
    }, [handleClose, isVisible]);

    const handleTransitionEnd = () => {
        if (isVisible) {
            Notices.markAsViewed(bigPromoViewedEvent);
        } else if (imageRef.current) {
            imageRef.current.style.display = 'none';
        }
    };

    useEffect(() => {
        window.addEventListener('invest-day-request-show-big-promo', handleRequestShow);

        return () => {
            window.removeEventListener('invest-day-request-show-big-promo', handleRequestShow);
        };
    }, [handleRequestShow, handleScroll]);

    useEffect(() => {
        if (isVisible) {
            document.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (isVisible) {
                document.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll, isVisible]);

    return (
        <>
            <div className={styles.bigPromoImage} ref={imageRef} />

            <div className={styles.bigPromo} onTransitionEnd={handleTransitionEnd}>
                <GridLayout>
                    <GridRow>
                        <div className={styles.bigPromoHeader}>
                            <button onClick={handleClose}>
                                <CrossOutlinedSize24 />
                            </button>
                        </div>
                    </GridRow>
                </GridLayout>

                <div className={styles.bigPromoContent}>
                    <h1 className={styles.bigPromoTitle}>{trls[TrlKeys.title]}</h1>
                    <h2 className={styles.bigPromoDescription}>{trls[TrlKeys.description]}</h2>
                    <Button Element="a" href={link} target="_blank" mode="primary" style="contrast" size="medium">
                        {trls[TrlKeys.button]}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default translation(InvestDay);
