import PropTypes from 'prop-types';

import { GridColumn, GridRow, GridLayout, VSpacing, useBreakpoint } from '@hh.ru/magritte-ui';

import FAQ from 'src/components/FAQ';
// temp_33950_next_line
import InvestDay from 'src/components/InvestDay';
import InvestDayBanner from 'src/components/InvestDay/banner';
import NewsBox from 'src/components/NewsBox';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useSendMagritteResumeSerpUxFb } from 'src/hooks/useSendMagritteResumeSerpUxFb';

import AdditionalServices from 'src/components/IndexPageEmployer/components/AdditionalServices';
import Calendar from 'src/components/IndexPageEmployer/components/Calendar';
import CalendarWithBanners from 'src/components/IndexPageEmployer/components/Calendar/CalendarWithBanners';
import Dashboard from 'src/components/IndexPageEmployer/components/Dashboard';

const TrlKeys = {
    newsTitle: 'index.newsTitle',
    articlesTitle: 'index.Articles',
};

const IndexEmployer = ({ trls }) => {
    useSendMagritteResumeSerpUxFb();

    const employerFaq = useSelector((state) => state.employerFaq);
    const isZp = useIsZarplataPlatform();
    const { isXS, isGtM } = useBreakpoint();
    const employerNews = useSelector((state) => state.employerNews);
    const employerArticles = useSelector((state) => state.employerArticles);

    const { bannerVisible, bigPromoVisible } = useSelector((state) => state.investDay);

    return (
        <>
            {/** temp_33950_next_line */}
            {bigPromoVisible && <InvestDay />}

            <GridLayout>
                <GridRow>
                    <GridColumn xs={4} s={8} m={12} l={isZp ? 12 : 9}>
                        {/** temp_33950_next_line */}
                        {!isGtM && bannerVisible && <InvestDayBanner />}

                        <Dashboard />

                        {isGtM && <CalendarWithBanners />}

                        {!isZp && (
                            <>
                                <VSpacing l={40} default={0} />

                                <GridColumn xs={0} s={0} m={0} l={9}>
                                    <FAQ items={employerFaq} isMedium={false} redesigned />
                                </GridColumn>
                            </>
                        )}
                    </GridColumn>

                    {!isZp && (
                        <GridColumn xs={4} s={8} m={12} l={3}>
                            <GridColumn xs={0} s={0} m={0} l={3}>
                                <VSpacing l={40} default={0} />
                                {/** temp_33950_next_line */}
                                {bannerVisible && <InvestDayBanner />}
                                <AdditionalServices />
                                <VSpacing default={40} />
                                <NewsBox
                                    items={employerNews}
                                    title={trls[TrlKeys.newsTitle]}
                                    href="/articles/site-news"
                                    view="cell"
                                />

                                <VSpacing default={40} />

                                <NewsBox
                                    items={employerArticles}
                                    title={trls[TrlKeys.articlesTitle]}
                                    href="/articles/employers"
                                    isArticle
                                    view="cell"
                                />
                            </GridColumn>

                            <GridColumn xs={4} s={8} m={12} l={0}>
                                <GridRow>
                                    <GridColumn xs={4} s={8} m={4}>
                                        <NewsBox
                                            items={employerNews}
                                            title={trls[TrlKeys.newsTitle]}
                                            href="/articles/site-news"
                                            view="cell"
                                        />

                                        <VSpacing default={40} gteM={0} />
                                    </GridColumn>

                                    <GridColumn xs={4} s={8} m={4}>
                                        <NewsBox
                                            items={employerArticles}
                                            title={trls[TrlKeys.articlesTitle]}
                                            href="/articles/employers"
                                            isArticle
                                            view="cell"
                                        />
                                    </GridColumn>

                                    <GridColumn xs={4} s={8} m={4}>
                                        <VSpacing default={40} gteM={0} />
                                        <AdditionalServices />
                                    </GridColumn>
                                </GridRow>

                                <GridColumn xs={4} s={8} m={12} l={0}>
                                    <VSpacing default={40} />
                                    {isXS ? <Calendar /> : <CalendarWithBanners />}
                                </GridColumn>

                                <GridColumn xs={4} s={8} m={12}>
                                    <FAQ items={employerFaq} gapTop isMedium={false} redesigned />
                                </GridColumn>
                            </GridColumn>
                        </GridColumn>
                    )}
                </GridRow>
            </GridLayout>
        </>
    );
};

IndexEmployer.propTypes = {
    trls: PropTypes.object,
};

export default translation(IndexEmployer);
