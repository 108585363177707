import { useCallback, useMemo, useState } from 'react';

import { Button, Select, SelectOption } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import { DUMMY_SHORT_ROLE_ID } from 'src/components/IndexPageAnonymous/Onboarding/utils';

const TrlKeys = {
    placeholder: 'index.onboarding.title.shortRole',
    dummyShortRole: 'index.onboarding.dummyShortRole',
    delimiter: {
        popular: 'index.onboarding.select.shortRole.popular',
        other: 'index.onboarding.select.shortRole.other',
    },
    save: 'index.onboarding.select.save',
};

interface ShortRolesSelectProps {
    selectedShortRoles: string[];
    setSelectedShortRoles: (roles: string[]) => void;
}

const ShortRolesSelect: TranslatedComponent<ShortRolesSelectProps> = ({
    selectedShortRoles,
    setSelectedShortRoles,
    trls,
}) => {
    const anonymousOnboarding = useSelector(({ anonymousOnboarding }) => anonymousOnboarding);

    const [selectKey, changeSelectKey] = useState(0);

    const onChange = useCallback(
        (roles: string[]) => {
            if (roles.includes(DUMMY_SHORT_ROLE_ID) && !selectedShortRoles.includes(DUMMY_SHORT_ROLE_ID)) {
                setSelectedShortRoles([DUMMY_SHORT_ROLE_ID]);
                changeSelectKey(selectKey + 1);
            } else if (selectedShortRoles.includes(DUMMY_SHORT_ROLE_ID) && roles.length > 1) {
                setSelectedShortRoles(roles.filter((item) => item !== DUMMY_SHORT_ROLE_ID));
                changeSelectKey(selectKey + 1);
            } else {
                setSelectedShortRoles(roles);
            }
        },
        [selectKey, selectedShortRoles, setSelectedShortRoles]
    );

    const options: SelectOption[] = useMemo(() => {
        if (anonymousOnboarding.isSnatch) {
            const { popularShortRoles, otherShortRoles } = anonymousOnboarding;
            return [
                { label: trls[TrlKeys.dummyShortRole], value: DUMMY_SHORT_ROLE_ID },
                { label: trls[TrlKeys.delimiter.popular], value: 'popular', type: 'delimiter' },
                ...popularShortRoles.map(({ id, name }) => ({
                    label: name,
                    value: id,
                })),
                { label: trls[TrlKeys.delimiter.other], value: 'other', type: 'delimiter' },
                ...otherShortRoles.map(({ id, name }) => ({
                    label: name,
                    value: id,
                })),
            ];
        }
        return [];
    }, [anonymousOnboarding, trls]);

    return (
        <Select
            key={selectKey}
            multiple
            searchable
            elevatePlaceholder
            size="large"
            placeholder={trls[TrlKeys.placeholder]}
            name="short-roles"
            options={options}
            value={selectedShortRoles}
            onChange={onChange}
            applyChangesButton={
                <Button mode="primary" style="accent">
                    {trls[TrlKeys.save]}
                </Button>
            }
        />
    );
};

export default translation(ShortRolesSelect);
