import { MutableRefObject, ReactNode } from 'react';

import { Avatar, Card as MagritteCard, Text, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import {
    ArrowUpDownOutlinedSize24,
    BoltOutlinedSize24,
    BubbleRoundDoubleOutlinedSize24,
    DocumentOutlinedSize24,
} from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './card.less';

export enum CardType {
    Up = 'up',
    AutoUpdate = 'auto-update',
    AddResume = 'add-resumes',
    KakdelaBanner = 'kakdela-banner',
}

interface CardProps {
    caption: ReactNode;
    description?: string;
    linkText: string;
    renderIcon?: () => ReactNode;
    onClick?: () => unknown;
    linkTo?: string;
    type?: CardType;
    isDisabled?: boolean;
    dataQa?: string;
    cardRef?: MutableRefObject<HTMLDivElement | null>;
}

const magritteIconMap = {
    [CardType.Up]: ArrowUpDownOutlinedSize24,
    [CardType.AutoUpdate]: BoltOutlinedSize24,
    [CardType.AddResume]: DocumentOutlinedSize24,
    [CardType.KakdelaBanner]: BubbleRoundDoubleOutlinedSize24,
};

const Card: TranslatedComponent<CardProps> = ({
    caption,
    description,
    linkText,
    renderIcon,
    type,
    isDisabled,
    onClick,
    linkTo,
    dataQa,
    cardRef,
}) => {
    const { isMobile } = useBreakpoint();

    const renderCard = () => {
        const Icon = type ? magritteIconMap[type] : undefined;
        return (
            <>
                <div ref={cardRef} data-qa={dataQa} className={styles.cardContentWrapper}>
                    <div className={styles.cardIconWrapper}>
                        {Icon && (
                            <Avatar
                                mode="icon"
                                icon={<Icon initial="positive" />}
                                size={48}
                                style="positive"
                                aria-label={''}
                                shapeCircle
                            />
                        )}
                        {renderIcon?.()}
                    </div>
                    <div className={styles.cardTextWrapper}>
                        <Text typography="label-2-regular">{caption}</Text>
                        <VSpacing default={8} />
                        {description && (
                            <div className={styles.cardDescription}>
                                <Text typography="label-3-regular" style="secondary">
                                    {description}
                                </Text>
                                <VSpacing default={8} />
                            </div>
                        )}
                        {isMobile && (
                            <Text typography="label-2-regular" style="accent">
                                {linkText}
                            </Text>
                        )}
                    </div>
                </div>
                {!isMobile && (
                    <Text typography="label-2-regular" style="accent">
                        {linkText}
                    </Text>
                )}
            </>
        );
    };

    const additionalProps: Record<string, unknown> = {};
    if (linkTo) {
        additionalProps.Element = Link;
        additionalProps.to = linkTo;
    }
    return (
        <div className={styles.cardContentWrapperContainer}>
            <MagritteCard
                {...additionalProps}
                actionCard
                showBorder
                padding={isMobile ? 16 : 24}
                borderRadius={isMobile ? 16 : 24}
                stretched
                onClick={onClick}
                showShadowOnHover
                disabled={isDisabled}
            >
                {renderCard()}
            </MagritteCard>
        </div>
    );
};

export default translation(Card);
